.color-rect {
  position: absolute;
  width: 3vw;
  height: 14vh;
  left: 0;
}

.adpushupRed {
  top: 28vh;
  background: #eb575c;
}

.sunsetOrange {
  top: 28vh;
  background: #ff4954;
}
.cornflowerBlue {
  top: 44vh;
  background: #634bfa;
}
.sunglow {
  top: 60vh;
  background: #ffe02d;
}
.tuna {
  top: 76vh;
  background: #2a2733;
}
.poppins-font {
  font-family: "Poppins";
}
.deepOrange {
  top: 28vh;
  background: #ff5722;
}

@media screen and (max-width: 768px) {
  .sunsetOrange {
    top: 20vh;
    background: #ff4954;
  }
  .cornflowerBlue {
    top: 36vh;
    background: #634bfa;
  }
  .sunglow {
    top: 52vh;
    background: #ffe02d;
  }
  .tuna {
    top: 68vh;
    background: #2a2733;
  }
}
.chart-parent {
  display: flex;
}
.margin-left{
  margin: 30%;
  margin-left: 3.5px;
}
.margin-right{
  margin-right: -9px;
}