/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

/* body::-webkit-scrollbar {
  width: 10px;
} */
